import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import SectionImage from '../components/SectionImage'
import BannerInfo from '../components/BannerInfo'
import { useLangContext } from '../context/lang.context'
import { gContainer } from '../style/config/globals'
import FormService from '../page/Services/FormService/FormService'
import Layout from '../components/Layout'
import IndustriesAll from '../page/Industry/IndustriesAll/IndustriesAll'

export default function Industry({ data, location }) {
  const { lang, setLang } = useLangContext()

  const industry = data.industries.edges.filter(
    ({ node }) => node.lang.slug === lang
  )[0]?.node

  const urlArray = location?.pathname?.split('/').filter((e) => e)
  const urlLang = urlArray.length > 2 && urlArray[urlArray?.length - 1]
  const langs = ['en', 'pl']

  /* eslint-disable */
  useEffect(() => {
    if (langs.includes(urlLang)) setLang(urlLang)
  }, [])

  useEffect(() => {
    if (!urlLang && lang !== industryLang) setLang(industryLang)
  }, [lang])

  /* eslint-enable */
  if (!industry) return <div />

  const {
    title,
    desc,
    image,
    lang: { slug: industryLang },
    sections,
    slug,
  } = industry

  // if (langs.includes(urlLang)) {
  //   setLang(urlLang)
  // } else if (lang !== serviceLang) setLang(serviceLang)

  return (
    <Layout>
      <Seo
        title={title}
        description={desc}
        lang={industryLang}
        image={image.file.url}
      />
      <SectionImage
        title={title}
        desc={desc}
        back={'INDUSTRY'}
        image={getImage(image)}
        imagePosition='center center'
      />
      <div css={[gContainer]}>
        {sections?.map(({ title, subtitle, desc, image, points }, id) => (
          <BannerInfo
            key={id}
            title={title}
            subtitle={subtitle}
            desc={desc.desc}
            points={points?.map((point) =>
              typeof point === 'object'
                ? point.text && point.link
                  ? point
                  : point.content
                : point
            )}
            image={getImage(image)}
          />
        ))}
      </div>
      <IndustriesAll />
      <FormService pages={'other'}/>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    industries: allContentfulIndustry(
      filter: { slug: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          lang {
            slug
          }
          slug {
            slug
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
            # title
            file {
              url
            }
          }
          sections {
            desc {
              desc
            }
            title
            #subtitle
            #points {
            #  content
            #  link
            #  text
            #}
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
              title
            }
          }
          title
          # headlines
          desc
        }
      }
    }
  }
`
