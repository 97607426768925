import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import ServiceThumbnail from '../../Services/ServicesAll/ServiceThumbnail'
import { s } from '../../../style'

const IndustriesAll = () => {
  const { lang } = useLangContext()
  const query = useStaticQuery(graphql`
    query {
      industries: allContentfulIndustry(sort: { fields: slug___order }) {
        edges {
          node {
            headlines
            image {
              title
              gatsbyImageData(
                width: 600
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
            }
            lang {
              slug
            }
            slug {
              slug
            }
          }
        }
      }
    }
  `)

  const industries = query.industries.edges
    .filter(({ node }) => node.lang.slug === lang)
    .map(({ node }) => ({
      headlines: node.headlines,
      image: node.image,
      slug: node.slug.slug,
      lang: node.lang.slug,
    }))
  // console.log(services)

  return (
    <section css={sOuter}>
      <div css={sInner}>
        {industries.map((service, id) => (
          <ServiceThumbnail key={id} {...service} prefix='/industry' />
        ))}
      </div>
    </section>
  )
}

const sOuter = {
  maxWidth: 1280,
  width: '100%',
  margin: '0 auto',
}

const sInner = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  margin: '0 50px 100px',
  gap: 50,
  [s.xs]: {
    margin: '0 25px 100px',
    gap: 25,
  },
  [s.sm_only]: {
    gap: 25,
  },
  [s.lg]: {
    margin: '50px 50px 200px',
  },
}

export default IndustriesAll
